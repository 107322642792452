<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-12 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('your_account_has_created') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li class="list-group-item">
						<div class="row">
							<div
								id="content"
								class="col-sm-12 text-center"
								style="margin: 30px 0 60px;">
								<i class="fas fa-check-circle fa-10x success-icon mb-4" />
								<div class="success-message margin-20">
									<!-- eslint-disable-next-line -->
									<h3 v-html="translate('success_title')" />
									<hr>
									<!-- eslint-disable-next-line -->
									<p v-html="translate('success_text_1')" />
									<!-- eslint-disable-next-line -->
									<p v-html="translate('success_text_2', {mailto:companyEmail, companyEmail})" />
								</div>
								<div class="clearfix text-center">
									<b-button
										v-if="$user.auth()"
										variant="primary"
										@click="$router.push({ name: 'Home' })">
										{{ translate('go_to_dashboard') }}
									</b-button>
									<b-button
										v-else
										variant="primary"
										@click="$router.push({ name: 'Login' })">
										{{ translate('go_to_login') }}
									</b-button>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-4 ml-auto my-4" />
		</div>
	</div>
</template>

<script>
import DocumentTitle from '@/mixins/DocumentTitle';
import commonMix from './mixins/Common';

export default {
	name: 'RegisterOldSuccess',
	mixins: [commonMix, DocumentTitle],
	data() {
		return {
			companyName: process.env.VUE_APP_TITLE,
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
		};
	},
	beforeRouteEnter(to, from, next) {
		next((vm) => {
			if (from.name !== 'RegisterOldConfirmation') {
				vm.$router.replace({ name: 'Login' });
			}
		});
	},
};
</script>
<style scoped>
.success-icon::before {
	color: #64B64B !important;
}
</style>
